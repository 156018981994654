<template>
  <div class="brochuresItem7">

    <div class="nav-section">

      <h1>Лінійка бренду Тева</h1>

      <a download href="/brochuresItem7.pdf" class="nav-section__download">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12 15.575C11.8667 15.575 11.7417 15.5543 11.625 15.513C11.5083 15.4717 11.4 15.4007 11.3 15.3L7.7 11.7C7.51667 11.5167 7.425 11.2833 7.425 11C7.425 10.7167 7.51667 10.4833 7.7 10.3C7.88333 10.1167 8.121 10.021 8.413 10.013C8.705 10.005 8.94234 10.0923 9.125 10.275L11 12.15V5C11 4.71667 11.096 4.479 11.288 4.287C11.48 4.095 11.7173 3.99934 12 4C12.2833 4 12.521 4.096 12.713 4.288C12.905 4.48 13.0007 4.71734 13 5V12.15L14.875 10.275C15.0583 10.0917 15.296 10.004 15.588 10.012C15.88 10.02 16.1173 10.116 16.3 10.3C16.4833 10.4833 16.575 10.7167 16.575 11C16.575 11.2833 16.4833 11.5167 16.3 11.7L12.7 15.3C12.6 15.4 12.4917 15.471 12.375 15.513C12.2583 15.555 12.1333 15.5757 12 15.575ZM6 20C5.45 20 4.979 19.804 4.587 19.412C4.195 19.02 3.99934 18.5493 4 18V16C4 15.7167 4.096 15.479 4.288 15.287C4.48 15.095 4.71733 14.9993 5 15C5.28333 15 5.521 15.096 5.713 15.288C5.905 15.48 6.00067 15.7173 6 16V18H18V16C18 15.7167 18.096 15.479 18.288 15.287C18.48 15.095 18.7173 14.9993 19 15C19.2833 15 19.521 15.096 19.713 15.288C19.905 15.48 20.0007 15.7173 20 16V18C20 18.55 19.804 19.021 19.412 19.413C19.02 19.805 18.5493 20.0007 18 20H6Z"
            fill="#35786E"/>
        </svg>
        Завантажити pdf
      </a>
    </div>

    <div class="content">

      <div class="green-block">

        <svg class="circle circle1" width="284" height="284" viewBox="0 0 284 284" fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.8">
            <mask id="mask0_2166_18178" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="284"
                  height="284">
              <circle cx="142" cy="142" r="142" fill="#D9D9D9"/>
            </mask>
            <g mask="url(#mask0_2166_18178)">
              <path d="M-43.1995 1071.3L962.38 278.741" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.2219 1065.38L962.358 272.816" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.2445 1059.45L962.335 266.892" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.267 1053.53L962.324 260.967" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.2896 1047.6L962.302 255.042" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.3008 1041.69L962.279 249.117" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.3234 1035.76L962.256 243.202" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.3458 1029.84L962.234 237.277" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.3684 1023.91L962.211 231.353" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.391 1017.99L962.2 225.428" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.4135 1012.06L962.178 219.503" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.4248 1006.14L962.155 213.578" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.4473 1000.21L962.133 207.653" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.4698 994.288L962.11 201.729" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.4923 988.373L962.087 195.813" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.5149 982.448L962.076 189.889" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.5375 976.523L962.054 183.964" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.5599 970.599L962.031 178.04" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.5712 964.673L962.009 172.114" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.5938 958.749L961.986 166.19" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.6163 952.824L961.963 160.265" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.6388 946.9L961.952 154.34" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.6614 940.984L961.93 148.425" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.6838 935.06L961.907 142.5" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.6952 929.135L961.885 136.576" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.7177 923.21L961.862 130.651" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.7402 917.285L961.839 124.726" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.7627 911.36L961.828 118.801" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.7853 905.436L961.806 112.876" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.8079 899.511L961.783 106.952" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.8191 893.586L961.761 101.027" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.8417 887.671L961.738 95.1118" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.8641 881.746L961.716 89.187" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.8867 875.821L961.693 83.2622" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.9092 869.897L961.682 77.3374" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.9318 863.972L961.659 71.4126" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.943 858.047L961.637 65.4878" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.9656 852.122L961.614 59.563" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.988 846.197L961.592 53.6382" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.0106 840.283L961.569 47.7236" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.0332 834.357L961.558 41.7983" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.0557 828.433L961.535 35.874" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.067 822.508L961.513 29.9492" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.0895 816.583L961.49 24.0244" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.1121 810.658L961.468 18.0991" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.1345 804.734L961.445 12.1748" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.1571 798.809L961.434 6.25" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.1796 792.884L961.411 0.325195" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.1909 786.969L961.389 -5.58984" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.2134 781.044L961.366 -11.5146" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.236 775.12L961.344 -17.4395" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.2585 769.195L961.321 -23.3643" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.281 763.27L961.31 -29.2891" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.3036 757.345L961.287 -35.2139" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.3148 751.42L961.265 -41.1387" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.3374 745.496L961.242 -47.0635" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.3599 739.581L961.22 -52.9785" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.3824 733.656L961.197 -58.9033" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.4049 727.731L961.186 -64.8281" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.4275 721.806L961.164 -70.7529" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.4387 715.881L961.141 -76.6777" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.4613 709.957L961.118 -82.6025" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.4839 704.032L961.096 -88.5273" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.5063 698.107L961.073 -94.4521" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.5289 692.192L961.062 -100.377" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.5514 686.267L961.04 -106.292" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.5627 680.342L961.017 -112.217" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.5852 674.418L960.995 -118.142" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.6078 668.493L960.972 -124.066" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.6302 662.568L960.95 -129.991" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.6528 656.643L960.938 -135.916" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.6754 650.718L960.916 -141.841" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.6979 644.793L960.893 -147.766" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.7091 638.878L960.871 -153.681" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.7317 632.954L960.848 -159.605" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.7543 627.029L960.826 -165.53" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.7767 621.104L960.814 -171.455" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.7993 615.179L960.792 -177.38" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.8218 609.254L960.769 -183.305" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.8331 603.33L960.747 -189.229" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.8556 597.405L960.724 -195.154" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.8782 591.49L960.702 -201.069" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.9008 585.565L960.69 -206.994" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.9232 579.641L960.668 -212.918" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.9458 573.716L960.645 -218.843" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.957 567.791L960.623 -224.768" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.9796 561.866L960.6 -230.693" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.0018 555.941L960.578 -236.618" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.0244 550.017L960.555 -242.542" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.0471 544.092L960.544 -248.467" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.0697 538.177L960.521 -254.382" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.0809 532.252L960.499 -260.307" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.1035 526.327L960.476 -266.232" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.126 520.402L960.454 -272.157" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.1486 514.478L960.431 -278.082" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.1711 508.553L960.42 -284.006" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.1936 502.628L960.397 -289.931" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.205 496.703L960.375 -295.856" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.2274 490.788L960.352 -301.771" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.25 484.863L960.33 -307.696" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.2725 478.938L960.307 -313.621" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.295 473.014L960.296 -319.545" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.3176 467.089L960.273 -325.47" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.3289 461.164L960.251 -331.395" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.3513 455.239L960.228 -337.32" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.3739 449.315L960.206 -343.245" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.3965 443.399L960.183 -349.169" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.4189 437.475L960.172 -355.084" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.4415 431.55L960.149 -361.009" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.4528 425.625L960.127 -366.934" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.4753 419.7L960.104 -372.859" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.4978 413.776L960.082 -378.783" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.5204 407.851L960.059 -384.708" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.5428 401.926L960.048 -390.633" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.5654 396.001L960.026 -396.558" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.5767 390.086L960.003 -402.473" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.5992 384.162L959.981 -408.397" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.6218 378.237L959.958 -414.322" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.6443 372.312L959.935 -420.247" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.6669 366.387L959.924 -426.172" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.6893 360.462L959.902 -432.097" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.7007 354.538L959.879 -438.021" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.7231 348.613L959.857 -443.946" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.7457 342.698L959.834 -449.861" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.7682 336.773L959.812 -455.786" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.7908 330.848L959.8 -461.711" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.8134 324.923L959.778 -467.636" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.8358 318.999L959.755 -473.561" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.847 313.074L959.733 -479.485" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.8696 307.149L959.71 -485.41" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.8922 301.224L959.688 -491.335" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.9147 295.299L959.676 -497.26" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.9373 289.384L959.654 -503.175" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.9597 283.459L959.631 -509.1" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.9711 277.535L959.609 -515.024" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.9935 271.61L959.586 -520.949" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.0161 265.685L959.564 -526.874" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.0387 259.76L959.552 -532.799" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.0612 253.835L959.53 -538.724" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.0837 247.911L959.507 -544.648" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.095 241.996L959.485 -550.563" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.1176 236.071L959.462 -556.488" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.14 230.146L959.44 -562.413" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.1626 224.221L959.417 -568.338" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.1851 218.296L959.406 -574.263" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.2076 212.372L959.383 -580.188" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.2189 206.447L959.361 -586.112" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.2415 200.522L959.338 -592.037" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.264 194.597L959.316 -597.962" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.2865 188.683L959.293 -603.876" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.3091 182.758L959.282 -609.801" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.3315 176.833L959.26 -615.726" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.3429 170.908L959.237 -621.651" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.3654 164.984L959.214 -627.576" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.3879 159.059L959.192 -633.5" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.4104 153.134L959.169 -639.425" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.433 147.209L959.158 -645.35" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.4556 141.294L959.136 -651.265" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.4668 135.369L959.113 -657.19" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.4893 129.444L959.09 -663.115" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.5118 123.52L959.068 -669.04" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.5344 117.595L959.045 -674.964" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.5569 111.67L959.034 -680.889" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.5795 105.745L959.012 -686.814" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.5907 99.8203L958.989 -692.739" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.6133 93.9053L958.967 -698.664" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.6357 87.9806L958.944 -704.579" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.6583 82.0557L958.921 -710.503" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.6808 76.1308L958.91 -716.428" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.7034 70.2062L958.888 -722.353" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.7146 64.2813L958.865 -728.278" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.7372 58.3564L958.843 -734.203" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.7598 52.4316L958.82 -740.127" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.7822 46.5069L958.797 -746.052" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.8048 40.5918L958.786 -751.967" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.8273 34.667L958.764 -757.892" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.8386 28.7422L958.741 -763.817" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.8611 22.8174L958.719 -769.742" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.8837 16.8926L958.696 -775.667" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.9062 10.9683L958.673 -781.591" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.9287 5.04345L958.662 -787.516" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
            </g>
          </g>
        </svg>

        <svg class="circle circle2" width="284" height="284" viewBox="0 0 284 284" fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.8">
            <mask id="mask0_2166_18178" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="284"
                  height="284">
              <circle cx="142" cy="142" r="142" fill="#D9D9D9"/>
            </mask>
            <g mask="url(#mask0_2166_18178)">
              <path d="M-43.1995 1071.3L962.38 278.741" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.2219 1065.38L962.358 272.816" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.2445 1059.45L962.335 266.892" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.267 1053.53L962.324 260.967" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.2896 1047.6L962.302 255.042" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.3008 1041.69L962.279 249.117" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.3234 1035.76L962.256 243.202" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.3458 1029.84L962.234 237.277" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.3684 1023.91L962.211 231.353" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.391 1017.99L962.2 225.428" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.4135 1012.06L962.178 219.503" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.4248 1006.14L962.155 213.578" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.4473 1000.21L962.133 207.653" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.4698 994.288L962.11 201.729" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.4923 988.373L962.087 195.813" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.5149 982.448L962.076 189.889" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.5375 976.523L962.054 183.964" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.5599 970.599L962.031 178.04" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.5712 964.673L962.009 172.114" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.5938 958.749L961.986 166.19" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.6163 952.824L961.963 160.265" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.6388 946.9L961.952 154.34" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.6614 940.984L961.93 148.425" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.6838 935.06L961.907 142.5" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.6952 929.135L961.885 136.576" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.7177 923.21L961.862 130.651" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.7402 917.285L961.839 124.726" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.7627 911.36L961.828 118.801" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.7853 905.436L961.806 112.876" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.8079 899.511L961.783 106.952" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.8191 893.586L961.761 101.027" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.8417 887.671L961.738 95.1118" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.8641 881.746L961.716 89.187" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.8867 875.821L961.693 83.2622" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.9092 869.897L961.682 77.3374" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.9318 863.972L961.659 71.4126" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.943 858.047L961.637 65.4878" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.9656 852.122L961.614 59.563" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.988 846.197L961.592 53.6382" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.0106 840.283L961.569 47.7236" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.0332 834.357L961.558 41.7983" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.0557 828.433L961.535 35.874" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.067 822.508L961.513 29.9492" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.0895 816.583L961.49 24.0244" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.1121 810.658L961.468 18.0991" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.1345 804.734L961.445 12.1748" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.1571 798.809L961.434 6.25" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.1796 792.884L961.411 0.325195" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.1909 786.969L961.389 -5.58984" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.2134 781.044L961.366 -11.5146" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.236 775.12L961.344 -17.4395" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.2585 769.195L961.321 -23.3643" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.281 763.27L961.31 -29.2891" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.3036 757.345L961.287 -35.2139" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.3148 751.42L961.265 -41.1387" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.3374 745.496L961.242 -47.0635" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.3599 739.581L961.22 -52.9785" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.3824 733.656L961.197 -58.9033" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.4049 727.731L961.186 -64.8281" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.4275 721.806L961.164 -70.7529" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.4387 715.881L961.141 -76.6777" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.4613 709.957L961.118 -82.6025" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.4839 704.032L961.096 -88.5273" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.5063 698.107L961.073 -94.4521" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.5289 692.192L961.062 -100.377" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.5514 686.267L961.04 -106.292" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.5627 680.342L961.017 -112.217" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.5852 674.418L960.995 -118.142" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.6078 668.493L960.972 -124.066" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.6302 662.568L960.95 -129.991" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.6528 656.643L960.938 -135.916" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.6754 650.718L960.916 -141.841" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.6979 644.793L960.893 -147.766" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.7091 638.878L960.871 -153.681" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.7317 632.954L960.848 -159.605" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.7543 627.029L960.826 -165.53" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.7767 621.104L960.814 -171.455" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.7993 615.179L960.792 -177.38" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.8218 609.254L960.769 -183.305" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.8331 603.33L960.747 -189.229" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.8556 597.405L960.724 -195.154" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.8782 591.49L960.702 -201.069" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.9008 585.565L960.69 -206.994" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.9232 579.641L960.668 -212.918" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.9458 573.716L960.645 -218.843" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.957 567.791L960.623 -224.768" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.9796 561.866L960.6 -230.693" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.0018 555.941L960.578 -236.618" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.0244 550.017L960.555 -242.542" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.0471 544.092L960.544 -248.467" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.0697 538.177L960.521 -254.382" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.0809 532.252L960.499 -260.307" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.1035 526.327L960.476 -266.232" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.126 520.402L960.454 -272.157" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.1486 514.478L960.431 -278.082" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.1711 508.553L960.42 -284.006" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.1936 502.628L960.397 -289.931" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.205 496.703L960.375 -295.856" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.2274 490.788L960.352 -301.771" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.25 484.863L960.33 -307.696" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.2725 478.938L960.307 -313.621" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.295 473.014L960.296 -319.545" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.3176 467.089L960.273 -325.47" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.3289 461.164L960.251 -331.395" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.3513 455.239L960.228 -337.32" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.3739 449.315L960.206 -343.245" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.3965 443.399L960.183 -349.169" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.4189 437.475L960.172 -355.084" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.4415 431.55L960.149 -361.009" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.4528 425.625L960.127 -366.934" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.4753 419.7L960.104 -372.859" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.4978 413.776L960.082 -378.783" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.5204 407.851L960.059 -384.708" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.5428 401.926L960.048 -390.633" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.5654 396.001L960.026 -396.558" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.5767 390.086L960.003 -402.473" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.5992 384.162L959.981 -408.397" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.6218 378.237L959.958 -414.322" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.6443 372.312L959.935 -420.247" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.6669 366.387L959.924 -426.172" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.6893 360.462L959.902 -432.097" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.7007 354.538L959.879 -438.021" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.7231 348.613L959.857 -443.946" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.7457 342.698L959.834 -449.861" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.7682 336.773L959.812 -455.786" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.7908 330.848L959.8 -461.711" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.8134 324.923L959.778 -467.636" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.8358 318.999L959.755 -473.561" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.847 313.074L959.733 -479.485" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.8696 307.149L959.71 -485.41" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.8922 301.224L959.688 -491.335" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.9147 295.299L959.676 -497.26" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.9373 289.384L959.654 -503.175" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.9597 283.459L959.631 -509.1" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.9711 277.535L959.609 -515.024" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.9935 271.61L959.586 -520.949" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.0161 265.685L959.564 -526.874" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.0387 259.76L959.552 -532.799" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.0612 253.835L959.53 -538.724" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.0837 247.911L959.507 -544.648" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.095 241.996L959.485 -550.563" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.1176 236.071L959.462 -556.488" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.14 230.146L959.44 -562.413" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.1626 224.221L959.417 -568.338" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.1851 218.296L959.406 -574.263" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.2076 212.372L959.383 -580.188" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.2189 206.447L959.361 -586.112" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.2415 200.522L959.338 -592.037" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.264 194.597L959.316 -597.962" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.2865 188.683L959.293 -603.876" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.3091 182.758L959.282 -609.801" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.3315 176.833L959.26 -615.726" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.3429 170.908L959.237 -621.651" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.3654 164.984L959.214 -627.576" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.3879 159.059L959.192 -633.5" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.4104 153.134L959.169 -639.425" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.433 147.209L959.158 -645.35" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.4556 141.294L959.136 -651.265" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.4668 135.369L959.113 -657.19" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.4893 129.444L959.09 -663.115" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.5118 123.52L959.068 -669.04" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.5344 117.595L959.045 -674.964" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.5569 111.67L959.034 -680.889" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.5795 105.745L959.012 -686.814" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.5907 99.8203L958.989 -692.739" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.6133 93.9053L958.967 -698.664" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.6357 87.9806L958.944 -704.579" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.6583 82.0557L958.921 -710.503" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.6808 76.1308L958.91 -716.428" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.7034 70.2062L958.888 -722.353" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.7146 64.2813L958.865 -728.278" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.7372 58.3564L958.843 -734.203" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.7598 52.4316L958.82 -740.127" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.7822 46.5069L958.797 -746.052" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.8048 40.5918L958.786 -751.967" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.8273 34.667L958.764 -757.892" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.8386 28.7422L958.741 -763.817" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.8611 22.8174L958.719 -769.742" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.8837 16.8926L958.696 -775.667" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.9062 10.9683L958.673 -781.591" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.9287 5.04345L958.662 -787.516" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
            </g>
          </g>
        </svg>

        <svg class="circle circle3" width="284" height="284" viewBox="0 0 284 284" fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.8">
            <mask id="mask0_2166_18178" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="284"
                  height="284">
              <circle cx="142" cy="142" r="142" fill="#D9D9D9"/>
            </mask>
            <g mask="url(#mask0_2166_18178)">
              <path d="M-43.1995 1071.3L962.38 278.741" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.2219 1065.38L962.358 272.816" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.2445 1059.45L962.335 266.892" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.267 1053.53L962.324 260.967" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.2896 1047.6L962.302 255.042" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.3008 1041.69L962.279 249.117" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.3234 1035.76L962.256 243.202" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.3458 1029.84L962.234 237.277" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.3684 1023.91L962.211 231.353" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.391 1017.99L962.2 225.428" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.4135 1012.06L962.178 219.503" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.4248 1006.14L962.155 213.578" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.4473 1000.21L962.133 207.653" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.4698 994.288L962.11 201.729" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.4923 988.373L962.087 195.813" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.5149 982.448L962.076 189.889" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.5375 976.523L962.054 183.964" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.5599 970.599L962.031 178.04" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.5712 964.673L962.009 172.114" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.5938 958.749L961.986 166.19" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.6163 952.824L961.963 160.265" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.6388 946.9L961.952 154.34" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.6614 940.984L961.93 148.425" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.6838 935.06L961.907 142.5" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.6952 929.135L961.885 136.576" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.7177 923.21L961.862 130.651" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.7402 917.285L961.839 124.726" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.7627 911.36L961.828 118.801" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.7853 905.436L961.806 112.876" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.8079 899.511L961.783 106.952" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.8191 893.586L961.761 101.027" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.8417 887.671L961.738 95.1118" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.8641 881.746L961.716 89.187" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.8867 875.821L961.693 83.2622" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.9092 869.897L961.682 77.3374" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.9318 863.972L961.659 71.4126" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.943 858.047L961.637 65.4878" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.9656 852.122L961.614 59.563" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.988 846.197L961.592 53.6382" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.0106 840.283L961.569 47.7236" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.0332 834.357L961.558 41.7983" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.0557 828.433L961.535 35.874" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.067 822.508L961.513 29.9492" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.0895 816.583L961.49 24.0244" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.1121 810.658L961.468 18.0991" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.1345 804.734L961.445 12.1748" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.1571 798.809L961.434 6.25" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.1796 792.884L961.411 0.325195" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.1909 786.969L961.389 -5.58984" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.2134 781.044L961.366 -11.5146" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.236 775.12L961.344 -17.4395" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.2585 769.195L961.321 -23.3643" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.281 763.27L961.31 -29.2891" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.3036 757.345L961.287 -35.2139" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.3148 751.42L961.265 -41.1387" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.3374 745.496L961.242 -47.0635" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.3599 739.581L961.22 -52.9785" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.3824 733.656L961.197 -58.9033" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.4049 727.731L961.186 -64.8281" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.4275 721.806L961.164 -70.7529" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.4387 715.881L961.141 -76.6777" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.4613 709.957L961.118 -82.6025" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.4839 704.032L961.096 -88.5273" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.5063 698.107L961.073 -94.4521" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.5289 692.192L961.062 -100.377" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.5514 686.267L961.04 -106.292" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.5627 680.342L961.017 -112.217" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.5852 674.418L960.995 -118.142" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.6078 668.493L960.972 -124.066" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.6302 662.568L960.95 -129.991" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.6528 656.643L960.938 -135.916" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.6754 650.718L960.916 -141.841" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.6979 644.793L960.893 -147.766" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.7091 638.878L960.871 -153.681" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.7317 632.954L960.848 -159.605" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.7543 627.029L960.826 -165.53" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.7767 621.104L960.814 -171.455" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.7993 615.179L960.792 -177.38" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.8218 609.254L960.769 -183.305" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.8331 603.33L960.747 -189.229" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.8556 597.405L960.724 -195.154" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.8782 591.49L960.702 -201.069" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.9008 585.565L960.69 -206.994" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.9232 579.641L960.668 -212.918" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.9458 573.716L960.645 -218.843" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.957 567.791L960.623 -224.768" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.9796 561.866L960.6 -230.693" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.0018 555.941L960.578 -236.618" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.0244 550.017L960.555 -242.542" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.0471 544.092L960.544 -248.467" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.0697 538.177L960.521 -254.382" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.0809 532.252L960.499 -260.307" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.1035 526.327L960.476 -266.232" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.126 520.402L960.454 -272.157" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.1486 514.478L960.431 -278.082" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.1711 508.553L960.42 -284.006" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.1936 502.628L960.397 -289.931" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.205 496.703L960.375 -295.856" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.2274 490.788L960.352 -301.771" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.25 484.863L960.33 -307.696" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.2725 478.938L960.307 -313.621" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.295 473.014L960.296 -319.545" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.3176 467.089L960.273 -325.47" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.3289 461.164L960.251 -331.395" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.3513 455.239L960.228 -337.32" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.3739 449.315L960.206 -343.245" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.3965 443.399L960.183 -349.169" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.4189 437.475L960.172 -355.084" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.4415 431.55L960.149 -361.009" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.4528 425.625L960.127 -366.934" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.4753 419.7L960.104 -372.859" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.4978 413.776L960.082 -378.783" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.5204 407.851L960.059 -384.708" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.5428 401.926L960.048 -390.633" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.5654 396.001L960.026 -396.558" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.5767 390.086L960.003 -402.473" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.5992 384.162L959.981 -408.397" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.6218 378.237L959.958 -414.322" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.6443 372.312L959.935 -420.247" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.6669 366.387L959.924 -426.172" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.6893 360.462L959.902 -432.097" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.7007 354.538L959.879 -438.021" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.7231 348.613L959.857 -443.946" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.7457 342.698L959.834 -449.861" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.7682 336.773L959.812 -455.786" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.7908 330.848L959.8 -461.711" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.8134 324.923L959.778 -467.636" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.8358 318.999L959.755 -473.561" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.847 313.074L959.733 -479.485" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.8696 307.149L959.71 -485.41" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.8922 301.224L959.688 -491.335" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.9147 295.299L959.676 -497.26" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.9373 289.384L959.654 -503.175" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.9597 283.459L959.631 -509.1" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.9711 277.535L959.609 -515.024" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.9935 271.61L959.586 -520.949" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.0161 265.685L959.564 -526.874" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.0387 259.76L959.552 -532.799" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.0612 253.835L959.53 -538.724" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.0837 247.911L959.507 -544.648" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.095 241.996L959.485 -550.563" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.1176 236.071L959.462 -556.488" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.14 230.146L959.44 -562.413" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.1626 224.221L959.417 -568.338" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.1851 218.296L959.406 -574.263" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.2076 212.372L959.383 -580.188" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.2189 206.447L959.361 -586.112" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.2415 200.522L959.338 -592.037" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.264 194.597L959.316 -597.962" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.2865 188.683L959.293 -603.876" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.3091 182.758L959.282 -609.801" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.3315 176.833L959.26 -615.726" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.3429 170.908L959.237 -621.651" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.3654 164.984L959.214 -627.576" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.3879 159.059L959.192 -633.5" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.4104 153.134L959.169 -639.425" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.433 147.209L959.158 -645.35" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.4556 141.294L959.136 -651.265" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.4668 135.369L959.113 -657.19" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.4893 129.444L959.09 -663.115" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.5118 123.52L959.068 -669.04" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.5344 117.595L959.045 -674.964" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.5569 111.67L959.034 -680.889" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.5795 105.745L959.012 -686.814" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.5907 99.8203L958.989 -692.739" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.6133 93.9053L958.967 -698.664" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.6357 87.9806L958.944 -704.579" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.6583 82.0557L958.921 -710.503" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.6808 76.1308L958.91 -716.428" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.7034 70.2062L958.888 -722.353" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.7146 64.2813L958.865 -728.278" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.7372 58.3564L958.843 -734.203" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.7598 52.4316L958.82 -740.127" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.7822 46.5069L958.797 -746.052" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.8048 40.5918L958.786 -751.967" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.8273 34.667L958.764 -757.892" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.8386 28.7422L958.741 -763.817" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.8611 22.8174L958.719 -769.742" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.8837 16.8926L958.696 -775.667" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.9062 10.9683L958.673 -781.591" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.9287 5.04345L958.662 -787.516" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
            </g>
          </g>
        </svg>

        <div class="order1 item mr mb">
          <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="50" cy="50" r="50" fill="white"/>
            <g clip-path="url(#clip0_2278_19551)">
              <path
                d="M55.8026 49.3359L65.0819 40.209C69.6026 35.6455 69.6026 28.2788 65.0819 23.7154C60.5396 19.1737 53.207 19.1737 48.6648 23.7154L25.6937 46.8151C21.1731 51.3568 21.1731 58.7452 25.6937 63.287C28.4407 66.0468 32.3774 67.2419 36.2059 66.4596"
                stroke="#005A85" stroke-width="3" stroke-miterlimit="8"/>
              <path
                d="M75.7022 57.398C75.7022 61.8746 66.8772 65.5036 55.9757 65.5036C45.0742 65.5036 36.2491 61.8746 36.2491 57.398C36.2491 52.9215 45.0742 49.2925 55.9757 49.2925C66.8772 49.2925 75.7022 52.9215 75.7022 57.398Z"
                stroke="#005A85" stroke-width="3" stroke-miterlimit="8"/>
              <path
                d="M36.2491 57.2676V65.4818C36.2491 70.0235 45.3121 73.696 55.9757 73.696C66.6393 73.696 75.7022 70.0235 75.7022 65.4818V57.2676"
                stroke="#005A85" stroke-width="3" stroke-miterlimit="8"/>
              <path d="M65.1251 50.0312L46.6099 64.5256" stroke="#005A85" stroke-width="3" stroke-miterlimit="8"/>
              <path d="M37.2225 35.2979L51.1739 49.3141" stroke="#005A85" stroke-width="3" stroke-miterlimit="8"/>
            </g>
            <defs>
              <clipPath id="clip0_2278_19551">
                <rect width="56" height="56" fill="white" transform="translate(21 19)"/>
              </clipPath>
            </defs>
          </svg>
          <p>Понад <span>50</span> торговельних найменувань лікарських засобів</p>
        </div>

        <div class="order2 item mb">
          <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="50" cy="50" r="50" fill="white"/>
            <g clip-path="url(#clip0_2278_19552)">
              <path d="M45.3268 70.5335H25.2771V24.2725H66.6535V40.5201" stroke="#005A85" stroke-width="3"
                    stroke-miterlimit="8"/>
              <path
                d="M50.1582 74.1396C46.7101 70.7035 46.7101 65.125 50.1582 61.6888L67.6537 44.2534C71.1018 40.8172 76.6995 40.8172 80.1475 44.2534C83.5956 47.6896 83.5956 53.2681 80.1475 56.7042L62.652 74.1396C59.2039 77.5758 53.6062 77.5758 50.1582 74.1396Z"
                stroke="#005A85" stroke-width="3" stroke-miterlimit="8"/>
              <path d="M59.0549 52.9072L71.4636 65.2732" stroke="#005A85" stroke-width="3" stroke-miterlimit="8"/>
              <path d="M65.2699 52.9076L71.5274 46.8201C72.9109 45.4838 75.1457 45.4838 76.5292 46.8201"
                    stroke="#005A85" stroke-width="3" stroke-miterlimit="8"/>
              <path d="M31.4921 38.0176H57.927" stroke="#005A85" stroke-width="3" stroke-miterlimit="8"/>
              <path d="M31.4921 43.0444H46.6677" stroke="#005A85" stroke-width="3" stroke-miterlimit="8"/>
              <path
                d="M36.5364 54.2861L37.7922 55.5164H40.325V57.9768L41.5807 59.2071L40.325 60.4373V62.8978H37.7922L36.5364 64.128L35.2806 62.8978H32.7478V60.4373L31.4921 59.2071L32.7478 57.9768V55.5164H35.2806L36.5364 54.2861Z"
                stroke="#005A85" stroke-width="3" stroke-miterlimit="8"/>
            </g>
            <defs>
              <clipPath id="clip0_2278_19552">
                <rect width="60" height="55" fill="white" transform="translate(24 23)"/>
              </clipPath>
            </defs>
          </svg>
          <p>Майже <span>40</span> рецептурних препаратів</p>
        </div>

        <div class="order3 item mr">
          <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="50" cy="50" r="50" fill="white"/>
            <g clip-path="url(#clip0_2278_19553)">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M51.8155 34.9821C51.8155 35.9292 51 36.6986 49.9903 36.6986C48.9806 36.6986 48.165 35.9292 48.165 34.9821C48.165 34.0351 48.9806 33.2656 49.9903 33.2656C51 33.2656 51.8155 34.0351 51.8155 34.9821Z"
                    stroke="#005A85" stroke-width="3" stroke-miterlimit="8" stroke-linejoin="round"/>
              <path d="M49.9902 62.4268V67.7341" stroke="#005A85" stroke-width="3" stroke-miterlimit="8"
                    stroke-linejoin="round"/>
              <path
                d="M53.7185 51.2598C54.1845 51.97 54.4175 52.779 54.4175 53.6274C54.4175 55.482 53.2525 57.1196 51.5243 57.6917L48.6311 58.6783C47.2525 59.132 46.3398 60.4342 46.3398 61.8942C46.3398 63.1767 47.0583 64.3408 48.1845 64.9129L49.9321 65.8205"
                stroke="#005A85" stroke-width="3" stroke-miterlimit="8" stroke-linejoin="round"/>
              <path d="M49.9902 43.3281V57.9086" stroke="#005A85" stroke-width="3" stroke-miterlimit="8"
                    stroke-linejoin="round"/>
              <path
                d="M52.5922 39.5796H49.0776C46.5534 39.5796 44.4951 41.671 44.4951 44.2359C44.4951 45.5972 45.0971 46.9192 46.1262 47.7873"
                stroke="#005A85" stroke-width="3" stroke-miterlimit="8" stroke-linejoin="round"/>
              <path
                d="M77.3884 43.9396L69.2913 29.7537L58.068 36.3041V23.1836H41.8933V36.3041L30.67 29.7537L22.5923 43.9396L33.8156 50.5097L22.5923 57.0798L30.6894 71.2656L41.9127 64.7153V77.8357H58.0874V64.7153L69.3107 71.2656L77.4078 57.0798L66.1845 50.5097L77.4078 43.9396H77.3884Z"
                stroke="#005A85" stroke-width="3" stroke-miterlimit="8" stroke-linejoin="round"/>
            </g>
            <defs>
              <clipPath id="clip0_2278_19553">
                <rect width="58" height="57" fill="white" transform="translate(21 22)"/>
              </clipPath>
            </defs>
          </svg>
          <p>Вже <span>11</span><br>терапевтичних напрямків</p>
        </div>

        <div class="order4 item">
          <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="50" cy="50" r="50" fill="white"/>
            <g clip-path="url(#clip0_2278_19554)">
              <path
                d="M61.3139 34.4521V76.5273C61.3139 77.1978 60.7755 77.7217 60.1129 77.7217H38.6386C37.9759 77.7217 37.4375 77.1769 37.4375 76.5273V34.4521"
                stroke="#005A85" stroke-width="3" stroke-miterlimit="8" stroke-linejoin="round"/>
              <path
                d="M35.2424 28.3549H38.8042V23.4726C38.8042 22.8021 39.3426 22.2573 39.9846 22.2573H58.9946C59.6573 22.2573 60.175 22.8021 60.175 23.4726V28.3549H63.7368V34.4524H35.2424V28.3549Z"
                stroke="#005A85" stroke-width="3" stroke-miterlimit="8" stroke-linejoin="round"/>
              <path d="M37.4583 68.2925H52.9479V42.9175H37.4583" stroke="#005A85" stroke-width="3" stroke-miterlimit="8"
                    stroke-linejoin="round"/>
              <path d="M60.3199 28.229H51.9539" stroke="#005A85" stroke-width="3" stroke-miterlimit="8"
                    stroke-linejoin="round"/>
              <path d="M38.7007 28.229H42.3867" stroke="#005A85" stroke-width="3" stroke-miterlimit="8"
                    stroke-linejoin="round"/>
            </g>
            <defs>
              <clipPath id="clip0_2278_19554">
                <rect width="31" height="58" fill="white" transform="translate(34 21)"/>
              </clipPath>
            </defs>
          </svg>
          <p>Близько <span>15</span> безрецептурних препаратів </p>
        </div>

      </div>

      <h2>Раміприл-Тева</h2>

      <picture>
        <source srcset="@/assets/img/brochures/brochuresItem7/main-mob.jpg" media="(max-width: 768px)"/>
        <img src="@/assets/img/brochures/brochuresItem7/main.jpg" alt="">
      </picture>

      <div class="items-wrapper">
        <div class="item">
          <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_2537_61272)">
              <path d="M50 0C22.3748 0 0 22.3748 0 50C0 77.6252 22.3748 100 50 100C77.6252 100 100 77.6252 100 50C100 22.3748 77.6252 0 50 0ZM73.4249 51.3732V65.2262C73.4249 69.8708 69.79 73.6268 65.3473 73.6268C60.9047 73.6268 57.2698 69.8708 57.2698 65.2262V61.4701C57.2698 59.8142 56.0582 58.441 54.5638 58.441C53.0695 58.441 51.8982 59.8142 51.8982 61.4701V61.6317V69.8708C51.8982 74.5153 48.2633 78.2714 43.8207 78.2714C39.378 78.2714 35.7431 74.5153 35.7431 69.8708V55.412C28.958 54.1195 23.8288 48.1826 23.8288 41.0339V29.1599C23.8288 24.5153 27.6252 21.6882 31.3005 21.6882C32.7948 21.6882 34.0065 22.8998 34.0065 24.3942C34.0065 25.8885 32.7948 27.1002 31.3005 27.1002C30.5735 27.1002 29.2407 27.5444 29.2407 29.1599V41.0339C29.2407 46.1228 33.3603 50.2423 38.4491 50.2827C43.538 50.2827 47.6575 46.1228 47.6575 41.0339V29.1599C47.6575 27.504 46.2843 27.1002 45.5977 27.1002C44.1034 27.1002 42.8918 25.8885 42.8918 24.3942C42.8918 22.8998 44.1034 21.6882 45.5977 21.6882C49.273 21.6882 53.0695 24.475 53.0695 29.1599V41.0339C53.0695 48.1826 47.9402 54.1195 41.1551 55.412V69.8708C41.1551 71.5266 42.3667 72.8998 43.8611 72.8998C45.3554 72.8998 46.567 71.5266 46.567 69.8708V69.588C46.567 69.588 46.567 69.588 46.567 69.5477V61.5105C46.567 56.8659 50.2019 53.1099 54.6446 53.1099C59.0872 53.1099 62.7221 56.8659 62.7221 61.5105V65.2666C62.7221 66.9225 63.9742 68.2956 65.4281 68.2956C66.8821 68.2956 68.1341 66.9225 68.1341 65.2666V51.3732C66.5186 50.4443 65.4685 48.748 65.4685 46.769C65.4685 43.7803 67.8918 41.3974 70.8401 41.3974C73.7884 41.3974 76.2116 43.8207 76.2116 46.769C76.2116 48.748 75.1212 50.4846 73.5057 51.4136" fill="#00A03B"/>
            </g>
            <defs>
              <clipPath id="clip0_2537_61272">
                <rect width="100" height="100" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          <p>
            <span>Інгібітор ангіотензинперетворю&shy;вального ферменту</span>
          </p>
        </div>
        <div class="item">
          <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_2537_61281)">
              <path d="M61.7933 55.6139H38.2069L30.8967 67.9725C30.7352 68.336 30.614 68.7399 30.614 69.1438C30.614 70.5574 31.6641 71.6882 33.0373 71.8094C33.1181 71.8094 33.1584 71.8094 33.2392 71.8094C33.32 71.8094 33.4008 71.8094 33.5219 71.8094H66.5187C66.5187 71.8094 66.7206 71.8094 66.8014 71.8094C66.8822 71.8094 66.9226 71.8094 67.0034 71.8094C68.4169 71.6882 69.4266 70.517 69.4266 69.1438C69.4266 68.7399 69.3458 68.336 69.1439 67.9725L61.8337 55.6139H61.7933Z" fill="#00A03B"/>
              <path d="M50 0C22.3748 0 0 22.3748 0 50C0 77.6252 22.3748 100 50 100C77.6252 100 100 77.6252 100 50C100 22.3748 77.6252 0 50 0ZM67.4879 77.1809C67.4879 77.1809 67.3263 77.1809 67.2456 77.1809C67.1648 77.1809 67.084 77.1809 67.0032 77.1809C66.8417 77.1809 66.6801 77.2213 66.5186 77.2213H33.5218C33.5218 77.2213 33.1987 77.2213 33.0372 77.1809C32.9564 77.1809 32.8756 77.1809 32.7948 77.1809C32.7141 77.1809 32.6333 77.1809 32.5525 77.1809C28.3926 76.7771 25.2423 73.3441 25.2423 69.1438C25.2423 67.8918 25.525 66.5993 26.1309 65.5089C26.1309 65.5089 26.1309 65.4685 26.1712 65.4281C26.1712 65.4281 26.1712 65.3877 26.1712 65.3473L40.2666 41.6397V28.1502H39.378C37.8837 28.1502 36.6721 26.9386 36.6721 25.4443C36.6721 23.9499 37.8837 22.7383 39.378 22.7383H60.6624C62.1567 22.7383 63.3683 23.9499 63.3683 25.4443C63.3683 26.9386 62.1567 28.1502 60.6624 28.1502H59.7738V41.6397L73.8691 65.3473C73.8691 65.3473 73.8691 65.3877 73.8691 65.4281C73.8691 65.4281 73.8691 65.4685 73.9095 65.5089C74.475 66.6397 74.7981 67.8918 74.7981 69.1438C74.7981 73.3441 71.6478 76.7771 67.4879 77.1809Z" fill="#00A03B"/>
              <path d="M54.7657 43.7803C54.483 43.2956 54.3618 42.7302 54.4022 42.2052C54.4022 42.1244 54.4022 42.0436 54.4022 42.0032V28.1906H45.6381V42.0032C45.6381 42.0032 45.6381 42.1648 45.6381 42.2052C45.6381 42.7302 45.5573 43.2956 45.2746 43.7803L41.4377 50.2423H58.6429L54.8061 43.7803H54.7657Z" fill="#00A03B"/>
            </g>
            <defs>
              <clipPath id="clip0_2537_61281">
                <rect width="100" height="100" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          <p>Раміприл-Тева таблетки має <span>два дозування:</span> 5 мг і 10 мг по 30 таблеток в упаковці </p>
        </div>
        <div class="item">
          <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_2537_61278)">
              <path d="M65.9935 28.9176H25.7673V69.1438H48.9499C49.1922 68.2553 49.6769 67.4879 50.3231 66.8417L65.9935 51.3732V28.8772V28.9176ZM55.4119 53.0695H36.3489C34.8546 53.0695 33.6429 51.8578 33.6429 50.3635C33.6429 48.8691 34.8546 47.6575 36.3489 47.6575H55.4119C56.9063 47.6575 58.1179 48.8691 58.1179 50.3635C58.1179 51.8578 56.9063 53.0695 55.4119 53.0695ZM55.4119 41.6397H36.3489C34.8546 41.6397 33.6429 40.4281 33.6429 38.9338C33.6429 37.4394 34.8546 36.2278 36.3489 36.2278H55.4119C56.9063 36.2278 58.1179 37.4394 58.1179 38.9338C58.1179 40.4281 56.9063 41.6397 55.4119 41.6397Z" fill="#00A03B"/>
              <path d="M51.7366 78.7157L56.9466 77.2617L53.2309 73.6268L51.7366 78.7157Z" fill="#00A03B"/>
              <path d="M68.6996 56.3409L56.0178 68.8207L61.7933 74.5154L74.475 61.9952C74.475 61.9952 74.3135 61.874 74.2327 61.7932L68.6188 56.3005L68.6996 56.3409Z" fill="#00A03B"/>
              <path d="M50 0C22.3748 0 0 22.3748 0 50C0 77.6252 22.3748 100 50 100C77.6252 100 100 77.6252 100 50C100 22.3748 77.6252 0 50 0ZM63.6914 80.2504C63.0452 80.8966 62.2375 81.3409 61.3893 81.5832L53.231 83.8853C52.7464 84.0065 52.2617 84.0872 51.7771 84.0872C50.3635 84.0872 48.9903 83.5218 47.9402 82.4717C46.567 81.0985 46.042 79.0388 46.6074 77.1809L47.3748 74.5154H24.9596C22.4152 74.5154 20.3554 72.4556 20.3554 69.9111V28.1502C20.3554 25.6058 22.4152 23.546 24.9596 23.546H66.7609C69.3053 23.546 71.3651 25.6058 71.3651 28.1502V46.1228L72.294 45.1939C73.3441 44.1842 74.7173 43.6591 76.0501 43.6591C77.3829 43.6591 78.7561 44.1842 79.8061 45.1939L85.622 50.9289C86.6317 51.9386 87.2375 53.3118 87.2375 54.7658C87.2375 56.2197 86.6721 57.5929 85.622 58.6026L63.6511 80.2504H63.6914Z" fill="#00A03B"/>
              <path d="M72.4958 52.5444L78.029 57.9968C78.029 57.9968 78.1905 58.1987 78.2713 58.2795L81.8658 54.7658L76.05 49.0307L72.4958 52.5444Z" fill="#00A03B"/>
            </g>
            <defs>
              <clipPath id="clip0_2537_61278">
                <rect width="100" height="100" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          <p>
            Призначають у дозі <span>від 2,5 до 10 мг на добу</span> незалежно від вживання їжі
          </p>
        </div>
        <div class="item">
          <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_2537_61284)">
              <path d="M49.9999 35.8643C46.0015 35.8643 42.7705 39.0953 42.7705 43.0937C42.7705 47.0921 45.9611 50.2423 49.9191 50.2827H50.0807C54.0387 50.2827 57.2293 47.0113 57.2293 43.0937C57.2293 39.1761 53.9983 35.8643 49.9999 35.8643Z" fill="#00A03B"/>
              <path d="M24.7576 32.2698C21.365 32.2698 18.6187 35.0162 18.6187 38.4087C18.6187 41.8013 21.3246 44.5073 24.6768 44.5477H24.8384C28.1905 44.5477 30.8965 41.7609 30.8965 38.4087C30.8965 35.0565 28.1502 32.2698 24.7576 32.2698Z" fill="#00A03B"/>
              <path d="M75.2827 32.2698C71.8902 32.2698 69.1438 35.0162 69.1438 38.4087C69.1438 41.8013 71.8498 44.5073 75.202 44.5477H75.3635C78.7157 44.5477 81.4621 41.7609 81.4621 38.4087C81.4621 35.0565 78.7157 32.2698 75.3231 32.2698" fill="#00A03B"/>
              <path d="M50 0C22.3748 0 0 22.3748 0 50C0 77.6252 22.3748 100 50 100C77.6252 100 100 77.6252 100 50C100 22.3748 77.6252 0 50 0ZM90.832 65.5089C89.3376 65.5089 88.126 64.2973 88.126 62.8029C88.126 55.7351 82.391 49.9596 75.3231 49.9192H75.1212C70.5977 49.9192 66.4782 52.3425 64.1761 56.1389C67.8514 59.8142 70.1131 64.8627 70.1131 70.4362C70.1131 71.9305 68.9015 73.1422 67.4071 73.1422C65.9128 73.1422 64.7415 71.9305 64.7415 70.4362C64.7415 62.3183 58.1583 55.6947 50.0808 55.6543C50.0808 55.6543 50 55.6543 49.9596 55.6543C49.9192 55.6543 49.8788 55.6543 49.8384 55.6543C41.7205 55.7351 35.1777 62.3183 35.1777 70.4362C35.1777 71.9305 33.9661 73.1422 32.4717 73.1422C30.9774 73.1422 29.7658 71.9305 29.7658 70.4362C29.7658 64.8627 32.0275 59.8142 35.7027 56.1793C33.4006 52.3829 29.2811 49.9596 24.7577 49.9192H24.5557C17.4879 49.9596 11.7528 55.7351 11.7528 62.8029C11.7528 64.2973 10.5412 65.5089 9.04685 65.5089C7.5525 65.5089 6.34087 64.2973 6.34087 62.8029C6.34087 55.6947 10.4604 49.5153 16.4378 46.4863C14.378 44.3861 13.0856 41.559 13.0856 38.4087C13.0856 32.0679 18.2553 26.8982 24.5961 26.8982C30.937 26.8982 36.1066 32.0679 36.1066 38.4087C36.1066 41.559 34.8142 44.4265 32.7544 46.4863C35.622 47.9402 38.126 50.1212 39.9031 52.8675C40.3473 52.6252 40.7512 52.4233 41.1955 52.2213C38.7722 49.9192 37.1971 46.6882 37.1971 43.0533C37.1971 36.1066 42.8514 30.4523 49.7981 30.4523C56.7447 30.4523 62.399 36.1066 62.399 43.0533C62.399 46.6882 60.8643 49.9192 58.4006 52.2213C58.8449 52.4233 59.2892 52.6656 59.6931 52.9079C61.4701 50.1212 63.9338 47.9402 66.8417 46.4863C64.7819 44.3861 63.5299 41.559 63.5299 38.4087C63.5299 32.0679 68.6995 26.8982 75.0404 26.8982C81.3813 26.8982 86.5509 32.0679 86.5509 38.4087C86.5509 41.559 85.2585 44.4265 83.1987 46.4863C89.1761 49.475 93.2956 55.6543 93.2956 62.8029C93.2956 64.2973 92.084 65.5089 90.5897 65.5089" fill="#00A03B"/>
            </g>
            <defs>
              <clipPath id="clip0_2537_61284">
                <rect width="100" height="100" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          <p>Застосовують у пацієнтів віком <span>від 18 років</span></p>
        </div>
        <div class="item">
          <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_2537_61287)">
              <path d="M61.9609 51.2129C61.0849 50.7412 60.5458 49.8315 60.5458 48.8544V44.5081L50.2358 51.1455C49.3935 51.6846 48.3491 51.7183 47.5067 51.2466C46.6307 50.7749 46.0916 49.8652 46.0916 48.8881V44.9124L37.7358 51.0445C36.9946 51.5836 36.0175 51.7183 35.1415 51.3814C34.2655 51.0445 33.659 50.2695 33.4906 49.3598L29.0768 25.1011H28.3019L25 51.8531V71.1253H75V44.5081L64.69 51.1455C63.8477 51.6846 62.8032 51.7183 61.9609 51.2466V51.2129ZM46.0243 64.9933C46.0243 66.4757 44.8113 67.6887 43.3288 67.6887C41.8464 67.6887 40.6334 66.4757 40.6334 64.9933V56.7385C40.6334 55.2561 41.8464 54.0431 43.3288 54.0431C44.8113 54.0431 46.0243 55.2561 46.0243 56.7385V64.9933ZM59.434 64.9933C59.434 66.4757 58.221 67.6887 56.7385 67.6887C55.2561 67.6887 54.0431 66.4757 54.0431 64.9933V56.7385C54.0431 55.2561 55.2561 54.0431 56.7385 54.0431C58.221 54.0431 59.434 55.2561 59.434 56.7385V64.9933Z" fill="#00A03B"/>
              <path d="M50 0C22.372 0 0 22.372 0 50C0 77.628 22.372 100 50 100C77.628 100 100 77.628 100 50C100 22.372 77.628 0 50 0ZM80.3571 73.8208C80.3571 75.3032 79.1442 76.5162 77.6617 76.5162H22.3046C20.8221 76.5162 19.6092 75.3032 19.6092 73.8208V51.6846C19.6092 51.6846 19.6092 51.4488 19.6092 51.3477L23.2143 22.0687C23.3827 20.721 24.5283 19.7102 25.876 19.7102H31.2668C32.5809 19.7102 33.6927 20.6536 33.9286 21.934L37.9717 44.1712L47.1361 37.4326C47.9447 36.8261 49.0566 36.7588 49.9326 37.1968C50.8423 37.6685 51.4151 38.5782 51.4151 39.5889V43.9353L61.7251 37.2978C62.5674 36.7588 63.6119 36.7251 64.4542 37.1968C65.3302 37.6685 65.8693 38.5782 65.8693 39.5553V43.9016L76.1792 37.2642C77.0216 36.7251 78.066 36.6914 78.9084 37.1631C79.7844 37.6348 80.3234 38.5445 80.3234 39.5216V73.7871L80.3571 73.8208Z" fill="#00A03B"/>
            </g>
            <defs>
              <clipPath id="clip0_2537_61287">
                <rect width="100" height="100" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          <p><span>Виробляють в ЄС</span> (Німеччина)</p>
        </div>
      </div>

      <div class="notes">
        <h3>Посилання</h3>
        <p>Інформація про лікарський засіб. РП&nbsp;№&nbsp;UA/16689/01/02, №&nbsp;UA/16689/01/03. Для професійної діяльності медичних і фармацевтичних працівників.
        </p>
        <p>Показання: лікування артеріальної гіпертензії, захворювання нирок, симптомної серцевої недостатності. Профілактика серцево-судинних захворювань у пацієнтів з наявністю в анамнезі ішемічної хвороби серця або інсульту чи захворювання периферичних судин, з діабетом і додатковим фактором ризику. Вторинна профілактика після перенесеного гострого інфаркту міокарда.  Найбільш частими побічними реакціями є: головний біль, запаморочення, непродуктивний подразнювальний кашель, бронхіт, синусит, задишка, запальні явища у шлунково-кишковому тракті, розлади травлення, дискомфорт у животі, диспепсія, діарея, нудота, блювання, висипання, зокрема макулопапульозні, м'язові спазми, міалгія, підвищення рівня калію у крові, артеріальна гіпотензія, ортостатичне зниження артеріального тиску, синкопе, біль у грудях, втомлюваність. Повний перелік побічних реакцій та протипоказань наведений в інструкції для медичного застосування лікарського засобу.</p>
      </div>

      <p class="promo">CBG-UA-01202. Термін дії матеріалу –  02.10.2024.</p>

      <RouterLink to="/brochures" class="back-link">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M8.425 11.9998C8.425 11.8665 8.44566 11.7415 8.487 11.6248C8.52833 11.5081 8.59933 11.3998 8.7 11.2998L12.3 7.6998C12.4833 7.51647 12.7167 7.4248 13 7.4248C13.2833 7.4248 13.5167 7.51647 13.7 7.6998C13.8833 7.88314 13.979 8.1208 13.987 8.4128C13.995 8.7048 13.9077 8.94214 13.725 9.1248L11.85 10.9998L19 10.9998C19.2833 10.9998 19.521 11.0958 19.713 11.2878C19.905 11.4798 20.0007 11.7171 20 11.9998C20 12.2831 19.904 12.5208 19.712 12.7128C19.52 12.9048 19.2827 13.0005 19 12.9998L11.85 12.9998L13.725 14.8748C13.9083 15.0581 13.996 15.2958 13.988 15.5878C13.98 15.8798 13.884 16.1171 13.7 16.2998C13.5167 16.4831 13.2833 16.5748 13 16.5748C12.7167 16.5748 12.4833 16.4831 12.3 16.2998L8.7 12.6998C8.6 12.5998 8.529 12.4915 8.487 12.3748C8.445 12.2581 8.42433 12.1331 8.425 11.9998Z"
            fill="#35786E"/>
        </svg>
        Повернутися
      </RouterLink>
    </div>

  </div>
</template>

<script>
import {mapGetters} from "vuex";
import brochuresItem1Text from "@/store/brochuresItem1/text-data"

export default {
  name: "brochuresItem7",

  data: () => ({}),
}
</script>

<style scoped lang="scss">
@import "../../assets/style/fonts.css";
@import "../../assets/style/vars";

.brochuresItem7 {
  position: relative;

  max-width: 820px;
  width: 100%;
  margin: 0 auto;

  padding: 40px 0;

  @media screen and (max-width: 768px) {
    padding: 29px 0;
  }

  sup {
    font-size: 60%;
    vertical-align: text-top;
  }

  ::v-deep sup {
    font-size: 60%;

    vertical-align: text-top;
  }

  .nav-section {
    padding: 0 20px;

    h1 {
      font-size: 48px;
      line-height: 120%;

      text-align: center;

      color: $grey;
      font-family: $EB;

      @media screen and (max-width: 660px) {
        font-size: 28px;
      }
    }

    &__download {
      font-size: 16px;
      line-height: 22px;

      display: flex;
      align-items: center;
      justify-content: center;

      color: $teal;
      font-family: $R;
      text-decoration: navajowhite;
      margin: 27px 0;

      svg {
        display: block;
      }
    }
  }

  .content {

    .green-block {
      position: relative;
      display: flex;
      flex-flow: row wrap;
      box-shadow: 0 15px 40px -10px rgba(0, 0, 0, 0.30);
      background: #00A03B;
      padding: 54px 44px;
      overflow: hidden;

      @media screen and (max-width: 860px) {
        padding: 30px 20px;
        flex-direction: column;
        align-items: center;
      }

      .circle {
        position: absolute;
        z-index: 1;

        &.circle1 {
          left: -140px;
          top: -140px;


        }

        &.circle2 {
          right: -60px;
          top: -110px;
          @media screen and (max-width: 860px) {
            right: -100px;
            top: initial;
            bottom: -170px;
          }
        }

        &.circle3 {
          left: 34%;
          bottom: -180px;

          @media screen and (max-width: 860px) {
            display: none;
          }
        }
      }

      .item {
        display: flex;
        align-items: center;
        max-width: 50%;
        width: 100%;
        position: relative;
        z-index: 2;

        @media screen and (max-width: 860px) {
          max-width: 320px;
          margin-bottom: 20px !important;
          &:last-child {
            margin-bottom: 0 !important;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }

        p {
          font-size: 24px;
          color: white;
          line-height: 120%;
          font-family: $SB;
          @media screen and (max-width: 860px) {
            font-size: 20px;
          }

          span {
            font-family: $EB;
            font-size: 32px;
            @media screen and (max-width: 860px) {
              font-size: 28px;
            }
          }
        }

        &.mr {
          margin-right: 20px;
          max-width: calc(50% - 20px);
          @media screen and (max-width: 860px) {
            max-width: 320px;
            margin-right: 0;
          }
        }

        &.mb {
          margin-bottom: 50px;
        }

        svg {
          max-width: 100px;
          min-width: 100px;
          width: 100%;
          height: auto;
          display: block;
          margin-right: 20px;
          @media screen and (max-width: 860px) {
            max-width: 74px;
            min-width: 74px;
          }
        }

        &.order1 {
          @media screen and (max-width: 860px) {
            order: 1;
          }
        }

        &.order2 {
          @media screen and (max-width: 860px) {
            order: 3;
          }
        }

        &.order3 {
          @media screen and (max-width: 860px) {
            order: 2;
          }
        }

        &.order4 {
          @media screen and (max-width: 860px) {
            order: 4;
          }
        }
      }
    }

    h2 {
      margin-top: 50px;
      margin-bottom: 24px;
      font-size: 36px;
      font-family: $R;
      color: $green;
      text-align: center;
      padding: 0 20px;

      @media screen and (max-width: 860px) {
        margin-top: 40px;
        font-family: $B;
        font-size: 20px;
        margin-bottom: 20px;
      }
    }

    picture {
      width: 100%;
      height: auto;
      display: block;

      img {
        width: 100%;
        height: auto;
        display: block;
      }
    }

    .items-wrapper {
      display: flex;
      flex-direction: column;
      margin-top: 24px;

      @media screen and (max-width: 860px) {
        margin-top: 0;
      }

      .item {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #E0E0E0;
        padding: 20px;

        p {
          font-size: 24px;
          color: $grey;
          font-family: $SB;
          line-height: 120%;

          @media screen and (max-width: 860px) {
            font-size: 18px;
          }

          span {
            font-family: $EB;
            color: $green;
            overflow-wrap: break-word;
            hyphens: manual;
          }
        }

        &:last-child {
          border-bottom: none;
        }

        svg {
          min-width: 100px;
          width: 100px;
          height: auto;
          display: block;
          margin-right: 20px;

          @media screen and (max-width: 860px) {
            min-width: 66px;
            width: 66px;
          }
        }
      }
    }

    .notes {
      margin-top: 60px;
      background: #EEE;
      padding: 40px 45px;

      @media screen and (max-width: 860px) {
        margin-top: 20px;
      }

      h3 {
        margin-bottom: 25px;
        color: $green;
        font-family: $M;
        font-size: 20px;
      }

      p {
        font-size: 13px;
        color: $grey;
        font-family: $R;
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .promo {
      margin-top: 20px;
      font-size: 12px;

      color: $grey;
      font-family: $R;

      @media screen and (max-width: 860px) {
        padding: 0 20px;
      }
    }

    .back-link {
      display: flex;
      align-items: center;
      font-size: 16px;
      color: $teal;
      font-family: $R;
      text-decoration: none;
      margin-top: 33px;
      @media screen and (max-width: 860px) {
        padding: 0 20px;
      }

      svg {
        display: block;
        width: 24px;
        height: auto;
        margin-right: 6px;
      }
    }
  }
}
</style>